body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.l-icon-material {
  z-index: 800 !important;
}

.material-icons.md-17 {
  font-size: 17px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  /*height: 100vh;*/
}

.marker-icon {
  color: #fff0 !important;
  background: none !important;
  border: 0px !important;
  border-width: 0px !important;
  z-index: 300 !important;
}

.loading {
  width: 25%;
  animation: grayscale-animation 2s infinite;
}

.progress {
   width: 25%;
   /*height: 24.6px;*/
   height: 2%;
   max-height: 24.6px;
   /*border-radius: 22.4px;*/
   border-radius: 10px;
   color: #ff004f;
   border: 2.2px solid #000;
   position: relative;
   /*margin-top: 2em;*/
   margin-top: 2%;
   background: #fafaf8;
   /*filter: drop-shadow(2px 4px 6px #000);*/
}

.progress::before {
   content: "";
   position: absolute;
   margin: 2.2px;
   /*inset: 0 100% 0 0;*/
   inset: 0 var(--inset) 0 0;
   border-radius: inherit;
   background: currentColor;
   transition-property: inset;
   transition-duration: 1s;
}

.executed-marker {
  z-index: 500 !important;
}

.executed-marker-error {
  z-index: 600 !important;
}
.executed-marker-error::after {
   content: "";
   background: white;
}

.senator-marker {
    color: #fff;
    font-size: 1em;
    line-height: 17px;
    text-align: center;
    vertical-align: bottom;
    border-width: 3px;
    border-style: solid;
    /*opacity: .9;*/
    font-weight: bolder;
    width: 100%;
    height: 100%;
}

.pickupMarker {
    
}

.deliveryMarker {
    border-radius: 1em;
}

.loadMarker {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
}

.unloadMarker {
  border-top-left-radius: 0em;
  border-top-right-radius: 0em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.mixMarker {
  border-top-left-radius: 0em;
  border-top-right-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  border-bottom-right-radius: 0em;
}

.voidMarker {
  border-top-left-radius: 0em;
  border-top-right-radius: 0em;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 1.5em;
}

.vehicle-marker {
  z-index: 900 !important;
  filter:
    drop-shadow(-1px -1px 0px #fff) 
    drop-shadow(1px -1px 0px #fff) 
    drop-shadow(1px 1px 0px #fff)
    drop-shadow(-1px 1px 0px #fff)
  /*filter: drop-shadow(0px 0px 0px #fafaf8) 
    drop-shadow(0px 0px 0px #fafaf8) 
    drop-shadow(0px 0px 0px #fafaf8)
    drop-shadow(0px 0px 0px #fafaf8);*/
}

.van-marker {
  /* height: 24px !important;
  width: 24px !important;
  filter: invert(8%) sepia(99%) saturate(6295%) hue-rotate(246deg) brightness(108%) contrast(141%);*/
}

.color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 3px;
}

.route-point {
}

.route-point-name {
  float: right;
  /*font-style: italic;*/
}

.route-point-info {
  display: inline-block;
}

.route-point-time-text {
  min-width: 100px;
  display: inline-block;
}

.route-point-time {
  text-align: right;
}

.route-point-container {
  max-height: 350px;
  overflow-y: overlay;
  width: 320px;
}

.delivery-action {
  display: inline-block;
}

.col {
 padding-right: 5px;
}

.action-code {
  min-width: 160px;
  display: inline-block;
}

.action-status {
  min-width: 80px;
  display: inline-block;
  text-align: right;
}

.delivery-action-icon {
  padding-left: 10px;
  width: 25px;
  /*height: 25px;*/
}

.vehicle-popup-data-title {
  min-width: 110px;
  display: inline-block;
}

@keyframes grayscale-animation {
  0% { filter: brightness(100%); }
  50% { filter: brightness(0%) }
  100% { filter: brightness(100%); }
}

.data-panel {
  position: relative;
}
