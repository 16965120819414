:root {
  --color-solid-resize-bar-handle: rgba(255, 0, 79, 0.2);
}

.HideHandler {
  display: none;
}

.ResizeHandleOuter {
  flex: 0 0 1.5em;
  position: relative;
  outline: none;
  --background-color: transparent;
}
.ResizeHandleOuterInternal {
  background-color: white;
  border-top: 1px solid #d5d5d5;
}
.ResizeHandleOuterExternal {
  z-index: 99999;
  flex-basis: 1em;
  border-left: 1px solid #d5d5d5;
  box-shadow: -2px -2px 2px -1px rgba(88, 88, 88, 0.2);
  background-color: whitesmoke;
}

.ResizeHandleInner {
  position: absolute;
  border-radius: 0.25em;
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ResizeHandleInnerInternal {
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
}
.ResizeHandleInnerExternal {
  left: 0.15em;
  bottom: 0.15em;
  top: 0.15em;
  right: 0.15em;
  opacity: 0;
}
.ResizeHandleOuterInternal[data-resize-handle-active] {
  --background-color: var(--color-solid-resize-bar-handle);
}
.ResizeHandleOuterInternal:hover {
  --background-color: var(--color-solid-resize-bar-handle);
}


.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}

.Handler {
  border-radius: 10px;
}
.ResizeHandleInnerExternal:hover {
  opacity: 1;
}
.HandlerHorizontal {
  background: #ff004f;
  width: 40px;
  height: 4px;
}
.HandlerVertical {
  background: #aaa;
  width: 4px;
  height: 40px;
}
